export const getPageSlugFromWpLink = (wpLink, wordPressUrl) => {
  if (!wpLink) return null;
  return wpLink.replace(wordPressUrl, '');
}

export const slugify = (string) => {
  return string.toLowerCase().replace(/ /g, '-')
}

export const scrollTo = (elementY, duration) => {
  if (typeof window !== 'undefined') {
    const startingY = window.pageYOffset;
    const diff = elementY - startingY;
    let start;

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp;
      // Elapsed milliseconds since start of scrolling.
      const time = timestamp - start;
      // Get percent of completion in range [0, 1].
      const percent = Math.min(time / duration, 1);
      window.scrollTo({
        top: startingY + diff * percent,
        left: 0,
        behaviour: 'smooth'
      });

      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    })
  }
}

export const isClient = typeof window !== 'undefined';

export const getUrlVars = () => {
  const queryString = isClient && window.location.search;
  if (queryString) {
    const queryParams = queryString.slice(1).split('&');
    return queryParams.reduce((acc, next) => {
      const paramKey = next.split('=')[0];
      const paramVal = next.split('=')[1];
      acc[paramKey] = paramVal;
      return acc;
    }, {});
  }
  return false;
}

export const getScrollPos = () => {
  return isClient ? window.scrollY : null;
}

// remove = the key (string) to remove
export const compileQuery = (paramKey = null, paramVal = null, remove = null) => {
  const queryParams = isClient && getUrlVars();
  const params = queryParams || {};
  if (remove) {
    delete params[remove];
  }
  if (paramKey && paramVal) {
    params[paramKey] = paramVal;
  }
  const queryString = Object.keys(params).map(queryParamKey => {
    return `${queryParamKey}=${params[queryParamKey]}`;
  }).join('&');
  return queryString ? `?${queryString}` : '';
}

export const childCategories = (categoryId, categories) => {
  var array = []
  const child = (id) => {
    categories && categories.map(a => parseInt(a.wordpress_parent) === parseInt(id) && (
      item(parseInt(a.acf.wordpress_id))
    ))
  }
  const item = (id) => {
    if (typeof (array[id]) === "undefined") { array.push(id) }
    child(parseInt(id))
  }
  categories && categories.map(a => parseInt(a.wordpress_parent) === parseInt(categoryId) && (
    item(parseInt(a.acf.wordpress_id))
  ))
  return array
}
