import React from 'react';
import * as Forms from '../../components/Forms';
import { decodeEntities } from '../../utils/htmlParse';
import './FormBlock.scss';

export const FormBlock = (props) => {
  const {
    heading = "Can't find what you're looking for? Contact us today.",
    smallHeading = 'Contact',
    form = 'general',
    partNumber = null,
    partDescription = null,
    location
  } = props;
  const getForm = (formVal) => {
    switch(formVal) {
      case 'general':
        return <Forms.General location={location} />;
      case 'contact':
        return <Forms.Contact location={location} />;
      case 'product':
        return <Forms.ProductEnquiry location={location} partNumber={partNumber} partDescription={partDescription} />;
      default:
        return null;
    }
  }
  return (
    <section className="form-block">
      <div className="wrapper">
        {smallHeading && (
          <span className="form-small-heading">
            {decodeEntities(smallHeading)}
          </span>
        )}
        {heading && (
          <h2 className="form-heading">
            {decodeEntities(heading)}
          </h2>
        )}
        <div className="form-container">
          {getForm(form)}
        </div>
      </div>
    </section>
  );
};
