import React from 'react';
import FormWrapper from '../FormWrapper';
import './Contact.scss';
import Leads from '../../components/Leads'
const Contact = ({ location }) => {
  return <Leads id={40}/>
  /*
  const formSettings = {
    location,
    url: "https://formbucket.com/f/buk_7IsMzWij7gXP7RKYNmABd1ui",
    className: "contact-page-form",
    recaptcha: false
  };
  return (
    <FormWrapper {...formSettings}>
      <div className="field first-name">
        <label htmlFor="firstname">
          <input type="text" id="firstname" name="first-name" placeholder="First Name" required />
          <span>First Name</span>
        </label>
      </div>
      <div className="field last-name">
        <label htmlFor="lastname">
          <input type="text" id="lastname" name="last-name" placeholder="Last Name" required />
          <span>Last Name</span>
        </label>
      </div>
      <div className="field company">
        <label htmlFor="company">
          <input type="text" id="company" name="company" placeholder="Company" />
          <span>Company</span>
        </label>
      </div>
      <div className="field email">
        <label htmlFor="email">
          <input type="email" id="email" name="email" placeholder="Email" required />
          <span>Email</span>
        </label>
      </div>
      <div className="field phone">
        <label htmlFor="phone">
          <input type="tel" id="phone" name="phone" placeholder="Phone" />
          <span>Phone</span>
        </label>
      </div>
      <div className="field office">
        <label htmlFor="office">
          <select id="office" name="office" required>
            <option value="" selected disabled>Office</option>
            <option value="Perth">Perth</option>
            <option value="Sydney">Sydney</option>
          </select>
          <span>Office</span>
        </label>
      </div>
      <div className="field message">
        <label htmlFor="message">
          <textarea name="message" id="message" placeholder="Message" rows="9" />
          <span>Message</span>
        </label>
      </div>
    </FormWrapper>
  );
  */
};

export default Contact;
