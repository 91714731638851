import React from 'react';
import FormWrapper from '../FormWrapper';
import './General.scss';
import Leads from '../../components/Leads'
const General = ({ location }) => {
  return <Leads id={42}/>
  /*
  const formSettings = {
    location,
    url: "https://formbucket.com/f/buk_C9qF9IDCG7plsEna7d1TDb2B",
    className: "general-contact-form",
    recaptcha: false
  };
  return (
    <FormWrapper {...formSettings}>
      <div className="field name">
        <label htmlFor="name">
          <input type="text" id="name" name="name" placeholder="Name" required />
          <span>Name</span>
        </label>
      </div>
      <div className="field company">
        <label htmlFor="company">
          <input type="text" id="company" name="company" placeholder="Company" />
          <span>Company</span>
        </label>
      </div>
      <div className="field email">
        <label htmlFor="email">
          <input type="email" id="email" name="email" placeholder="Email" required />
          <span>Email</span>
        </label>
      </div>
      <div className="field phone">
        <label htmlFor="phone">
          <input type="tel" id="phone" name="phone" placeholder="Phone" />
          <span>Phone</span>
        </label>
      </div>
      <div className="field message">
        <label htmlFor="message">
          <textarea name="message" id="message" rows="9" placeholder="Message" />
          <span>Message</span>
        </label>
      </div>
    </FormWrapper>
  );
  */
};

export default General;
