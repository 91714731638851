import React from 'react';
import FormWrapper from '../FormWrapper';
import './ProductEnquiry.scss';
import Leads from '../../components/Leads'

const ProductEnquiry = ({ location, partNumber, partDescription, sendTo, inPopup, productLink }) => {
  /*
  const formSettings = {
    location,
    url: "https://formbucket.com/f/buk_9TWXwXF4VPF6g8LrHLU6paI9",
    className: `product-enquiry-form${inPopup ? ' in-popup' : ''}`,
    recaptcha: false
  };
  */
  const settings = [
    {
      "type": "text",
      "name": "name",
      "placeholder": "Name",
      "required": true,
      "className": "half"
    },
    {
      "type": "text",
      "name": "company",
      "placeholder": "Company",
      "className": "half"
    },
    {
      "type": "email",
      "name": "email",
      "placeholder": "Your Email",
      "required": true,
      "className": "half"
    },
    {
      "type": "phone",
      "name": "phone",
      "placeholder": "Phone",
      "required": true,
      "className": "half"
    },
    {
      "type": "text",
      "name": "part-number",
      "placeholder": "Part Number",
      "readOnly": true,
      "setvalue": partNumber,
    },
    {
      "type": "text",
      "name": "description",
      "placeholder": "Description",
      "readOnly": true,
      "setvalue": partDescription,
    },
    {
      "type": "textarea",
      "name": "message",
      "placeholder": "Message",
      "required": true,
      "className": ""
    }
  ]

  return <Leads id={41} formFields={settings}  sendTo={sendTo} productLink={productLink} />
  /*
  return (
    <FormWrapper {...formSettings}>
      <div className="field name">
        <label htmlFor="name">
          <input type="text" id="name" name="name" placeholder="Name" required />
          <span>Name</span>
        </label>
      </div>
      <div className="field company">
        <label htmlFor="company">
          <input type="text" id="company" name="company" placeholder="Company" />
          <span>Company</span>
        </label>
      </div>
      <div className="field email">
        <label htmlFor="email">
          <input type="email" id="email" name="email" placeholder="Email" required />
          <span>Email</span>
        </label>
      </div>
      <div className="field phone">
        <label htmlFor="phone">
          <input type="tel" id="phone" name="phone" placeholder="Phone" />
          <span>Phone</span>
        </label>
      </div>
      <div className="field part-number">
        <label htmlFor="part-number">
          <span>Part Number</span>
          <input
            type="text"
            id="part-number"
            name="part-number"
            value={partNumber}
            readOnly
          />
        </label>
      </div>
      <div className="field part-description">
        <label htmlFor="description">
          <span>Description</span>
          <input
            type="text"
            id="description"
            name="part-description"
            value={partDescription}
            readOnly
          />
        </label>
      </div>
      <div className="field message">
        <label htmlFor="message">
          <textarea name="message" id="message" rows="5" placeholder="Message" />
          <span>Message</span>
        </label>
      </div>
    </FormWrapper>
  );
  */
};

export default ProductEnquiry;
